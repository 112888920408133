@import 'variables';
body{
  .comment-input__suggestions{
    max-height: 280px;
    overflow: auto;
    padding: 10px;
    border: 1px solid rgba(0,0,0,0.15) !important;
    > div{
      border: 1px solid rgba(0,0,0,0.15) !important;
    }
    .list{
      background: #fff;
      
      font-size: 14px;
    }
    .item{
      padding: 5px 15px;
      border-bottom: 1px solid rgba(0,0,0,0.15);
      // &::fo {
      //   backgroundColor: #cee4e5
      // }
    }
  }
  .MuiPaper-root{
    .workscope-llp {

      .fixed-table{
        overflow-x: visible;
        .makeStyles-tableWrapper-5, .makeStyles-tableWrapper-13{
          overflow-x: visible;
        }
        .MuiTable-root {
          tr {
            white-space: nowrap;
            td {
              white-space: nowrap;
            }
          }
        }
        overflow-y: scroll;
      }
    }
  }
  .reference-no{
    padding-left: 10px;
    .show-ref{  
      @include elementStyles($size: 16px, $fontFamily: $ibmplexMedium, $color: #000); 
    }
    .add-ref{
      cursor: pointer;
      @include elementStyles($size: 16px, $fontFamily: $ibmplexMedium, $color: #3f51b5); 
    }
  }
  .custom-region-variables{
    .action-cta{
      float: right;
      li{
        margin-left: 10px;
      }
    }
    h6{
      @include elementStyles($size: 13px, $fontFamily: $ibmplexLight, $color: #000000, $margin:0 0 8px 0);
    }
    .rangeslider-horizontal{
      height:9px;
      margin: 0;
      box-shadow: 0 0 0;
      .rangeslider__handle{
        width: 18px;
        height: 18px;
        background:#ffffff;
        border:1px solid #bcc4d4;
        box-shadow:0 2px 4px 0 rgba(0,0,0,0.20);
      }
      .rangeslider__handle:after{
        top: 4px;
        left: 4px;
        width:10px;
        height:10px;
        box-shadow: 0 0 0;
        background:#b1bfd0;
      }
      .rangeslider__fill{
        background:#2f8cff;
      }
      .rangeslider__labels{
        display: none;
      }
      .rangeslider__handle-tooltip{
        display: inline-block;
        width: 100px;
      }
    }
  }
  .list-analytic-sw{
    border: 1px solid #d7d7d7;
    display: flex;
    align-items: center;
    border-radius: 3px;
    width: 80px;
    background: #EEE;
    // margin-bottom: 10px;
    margin-left: auto;
    span{
      width: 40px;
      padding:3px 5px;
      text-align: center;
      cursor: pointer;
      &.active{
        background: #ffffff;
      }
    }
  }
  .events-bar-chart{
    border: 1px solid #d7d7d7;
    margin-bottom: 20px;
    .fleet-title{
      padding:10px;
      border-bottom: 1px solid #d7d7d7;
      margin-bottom: 15px;
      background: #ffffff;
      h4{
        @include elementStyles($size: 17px, $fontFamily: $ibmplexMedium, $weight:500, $color: #5e5e5e); 
      }
    }
    .events-bar-chart-title{
      padding:10px;
      border-bottom: 1px solid #d7d7d7;
      margin-bottom: 15px;
      background: #B5BEDB;
      h4{
        @include elementStyles($size: 14px, $fontFamily: $ibmplexMedium, $weight:500, $color: #000); 
      }
    }
  }
  .commverge-intermediate-card{
    .sv-edit-icon{
      font-size: 16px;
      position: relative;
      top: 2px;
      left: 3px;
    }
    .MuiFormControl-marginNormal{
      margin-bottom: 0 !important;
    }
    position: relative;
    padding-left: 195px;
    .gauge-chart{
      position: relative;
      .unit{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexBold, $color: #000); 
      }
      .unit-type{
        @include elementStyles($size: 11px, $fontFamily: $ibmplexBold, $color: #000); 
      }
      .gauge-title{
        position: absolute;
        top:52%;
        left: 50%;
        transform: translate(-50%, -60%);
        z-index: 10;
        text-align: center;
        width: 100%;
        .amount{
          @include elementStyles($size: 18px, $fontFamily: $ibmplexBold, $color: #8e8f08); 
        }
        
      }
      .min-value{
        position: absolute;
        bottom: 20px;
        left: 30px;
        z-index: 10;
        font-size: 11px;
        text-align: center;
        .amount{
          @include elementStyles($size: 15px, $fontFamily: $ibmplexBold, $color: #DF5353); 
        }
      }
      .max-value{
        position: absolute;
        bottom: 20px;
        right: 30px;
        z-index: 10;
        font-size: 11px;
        text-align: center;
        .amount{
          @include elementStyles($size: 15px, $fontFamily: $ibmplexBold, $color: #25900a); 
        }
      }
    }
    .commverge-assembly-menu{
      width: 180px;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: #F1F0F0;
      .menu-option{
        border-bottom: 1px solid #d7d7d7;
        cursor: pointer;
        padding: 10px 5px;
        h6{
          @include elementStyles($size: 14px, $fontFamily: $ibmplexRegular, $color: #000000);
        }
        p{
          @include elementStyles($size: 11px, $fontFamily: $ibmplexRegular, $color: #000000);
        }
        &.active{
          background: #ffffff;
        }
      }
    }
    .commverge-assembly-projection{
      padding: 15px 15px 15px 0;
      .assembly-projection-events{
        .mnt-table-list{
          border: 1px solid #d7d7d7;
          margin-bottom: 15px;
          .event-name{
            border-bottom: 1px solid #d7d7d7;
            padding: 10px 10px;
            background: #B5BEDB;
            h4{
              @include elementStyles($size: 14px, $fontFamily: $ibmplexMedium, $weight:400, $color: #000000);
            }
          }
          .event-lists{
            padding: 15px 10px;
            .table-list{
              margin-top: 15px;
              table{
                thead,th{
                  background: #d9dff2 !important;
                }
              }
              h4{
                @include elementStyles($size: 15px, $fontFamily: $ibmplexRegular, $weight:400, $color: #000000);
              }
            }
          }
        }
      }
      .engine-events-projection{
        .engine-events-card{
          .life-on-wings{
            margin-bottom: 15px;
            border: 1px solid #d7d7d7;
            .low-title{
              padding: 10px;
              background: #B5BEDB;
              h4{
                @include elementStyles($size: 14px, $fontFamily: $ibmplexMedium, $weight:500, $color: #000000);
              }
            }
          }
          .engine-events-table{
            thead{
              background: #d9dff2 !important;
              th{
                background: #d9dff2 !important;
                font-family: $ibmplexMedium;
                font-weight: 500;
              }
            }
            .engine-event-cell{
              padding: 0;
              background: #ebeff6;
              h6{
                @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $weight:400, $color: #000);
              }
            }
          }
        }
        .engine-basic-info {
          margin-bottom: 15px;
          h4{
            @include elementStyles($size: 15px, $fontFamily: $ibmplexRegular, $color: #000, $margin:0 0 20px 0);
          }
        }
      }
      .even-row-eol{
        background-color: #f6f8ff;
        &:hover{
          background-color: #e5ebff;
        }
      }
      .odd-row-eol{
        background-color: #fff;
        box-shadow: 2px 2px #f2f2f2;
        &:hover{
          background-color: #e5ebff;
        }
      }
    }
    .commverge-assembly-card{
      padding-right: 15px;
      margin-bottom: 15px;
      padding-bottom: 15px;
      .apu-assembly-card{
        padding-top: 15px;
        position: relative;
        .assembly-cta-list{
          // position: absolute;
          // right: 5px;
          // top: 5px;
          float: right;
          li{
            margin-left: 10px;
          }
        }
        table{
          width: 100%;
          td{
            padding: 8px 10px;
          }
        }
        .apu-assembly-event{
          margin-top: 10px;
          border: 1px solid #d7d7d7;
          .event-name{
            border-bottom: 1px solid #d7d7d7;
            padding: 8px 15px;
            background: #B5BEDB;
            h4{
              @include elementStyles($size: 14px, $fontFamily: $ibmplexMedium, $weight:500, $color: #000);
            }
          }
          .event-details{
            padding: 10px 15px 15px 15px;
          }
        }
      }
      .eoi-assembly-card{
        padding-top: 15px;
        position: relative;
        min-height: 167px;
        .input-option{
          .MuiFormControl-marginNormal{
            margin: 4px 0px !important;
          }
        }
        .input-number-filed{
          margin: 4px 0px !important;
        }
        .lease-count-head{
              background-color: #f2f2f2;
          h3{
            color: #551a8b;
            font-size: 15px;
          }
        }
        .lease-date-heading{
          background: #B5BEDB;
        }
        .MuiAccordionSummary-content{
          justify-content: space-between;
        }
        .MuiButton-outlined {
          padding: 2px 5px;
          margin: 10px 0px;
        }
        .assembly-cta-list{
          // position: absolute;
          // right: 5px;
          // top: 5px;
          float: right;
          li{
            margin-left: 10px;
          }
        }
        table{
          width: 100%;
          td{
            padding: 8px 10px;
          }
        }
        .eoi-assembly-event{
          .action-eoi{
            padding-top: 14px;
          }
          margin-top: 10px;
          border: 1px solid #d7d7d7;
          .event-name{
            border-bottom: 1px solid #d7d7d7;
            padding: 8px 15px;
            background: #B5BEDB;
            h4{
              @include elementStyles($size: 14px, $fontFamily: $ibmplexMedium, $weight:500, $color: #000);
            }
          }
          .event-details{
            padding: 10px 15px 15px 15px;
          }
        }
      }
      .engine-assembly-card{
        padding-top: 20px;
        position: relative;
        table{
          td{
            padding: 8px 10px;
          }
        }
        .engine-shop-visit-wk{
          margin-bottom: 15px;
          .shop-visit-info{
            .engine-shop-visit-table{
              table{
                th,td{
                  padding: 8px 15px;
                }
                .full-row{
                  background: #d9dff2;
                }
                th{
                  background: #B5BEDB;
                  @include elementStyles($size: 14px, $fontFamily: $ibmplexMedium, $color: #000);
                }
                tbody{
                  tr{
                    td{
                      font-size: 15px;
                      &:first-child{
                        @include elementStyles($size: 14px, $fontFamily: $ibmplexMedium, $color: #000);
                      }
                    }
                  }
                }
              }
            }

            .sv-blk{
              padding: 5px;
              .blk-title{
                @include elementStyles($size: 15px, $fontFamily: $ibmplexRegular, $color: #4b35fc, $margin:0 0 8px 0);
                text-decoration: underline;
              }
              table{
                width: 100%;
                td{
                  padding: 5px;
                }
              }
              &.sv-blk-second{
                background: #faf4ea;
                height: 218px;
              }
              &.sv-blk-third{
                background: #e8eaf5;
                height: 218px;
              }
              .label-title{
                @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
              }
              h6{
                @include elementStyles($size: 15px, $fontFamily: $ibmplexRegular, $color: #000000);
              }
              .workscope-module-type{
                li{
                  .MuiFormControlLabel-label{
                    font-size: 13px;
                  }
                  &:last-child{
                    label{
                      margin-right: 0 !important;
                    }
                  }
                }
              }
            }
          }
        }
        .engine-assembly-event{
          border: 1px solid #d7d7d7;
          position: relative;
          
          .event-name{
            border-bottom: 1px solid #d7d7d7;
            padding: 7px 15px;
            background: #B5BEDB;
            h4{
              @include elementStyles($size: 14px, $fontFamily: $ibmplexMedium, $weight:500, $color: #000);
            }
            .assembly-cta-list{
              margin-left: auto;
              li{
                margin-left: 10px;
              }
            }
          }
          .event-details{
            .event-basic-detail{
              padding: 10px 15px 15px 15px;
            }
            .prsv-llp-event{
              padding: 10px 15px 15px 15px;
              background: #F8FCFD;
              h4{
                @include elementStyles($size: 14px, $fontFamily: $ibmplexMedium, $weight:500, $color: #000, $margin:0 0 6px 0);
              }
            }
          }
          .event-cut-off-blk{
            padding:10px 15px 15px 15px;
            background: #FFFAF2;
          }
        }
      }
    }
  }
  .mr-calculator-section{
    .mr-title{
      margin-bottom: 16px;
    }
    .mr-projection-tabs{
      .MuiTabs-scroller{
        border-bottom: 0 !important;
        margin-bottom: 0 !important;
      }
    }
    .mr-log-info{
      ul{
        li{
          margin-right: 10px;
          &:last-child{
            margin-right: 0;
          }
          p{
            @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
          }
          h6{
            @include elementStyles($size: 14px, $fontFamily: $ibmplexSemiBold, $color: #000000);
          }
        }
      }
    }
    .asset-card {
      background: #F0F0F1;
      margin-top: 30px;
      -webkit-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.44);
      -moz-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.44);
      box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.44);
    }
    .mr-logs-list{
      .new-finance-rpt-cta{
        // margin-bottom: 10px;
        .export-and-entry{
          padding-right: 10px;
          float: right;
          ul.list-inline{
            li.list-inline-item{
              margin-left: 10px;
            }
          }
        }

      }
    }
    .mr-calculator-initiate{
      .gmnoprint{display: none !important;}
      .assembly-icons{
        margin-bottom: 6px;
        li{
          margin-right: 30px;
          cursor: pointer;
          .assembly-icon{
            position: relative;
            display: inline-block;
            padding: 8px;
            .overlay{
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              right: 0;
              z-index: 99;
              background: rgba(0, 0, 0, 0.35);
              svg{
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                z-index: 99;
                font-size: 60px;
                color: #2670CA;
                text-shadow: 0 2px 2px rgba(0,0,0,0.4);
              }
            }
            img{
              width: 40px;
            }
          }

        }
      }
      .mr-initiate-card{
        margin-bottom: 15px;
        position:relative;
        .mr-initiate-card-title{
          @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #000);
          padding: 8px 15px;
          border-bottom: 1px solid #d7d7d7;
          span{
            @include elementStyles($size: 11px, $fontFamily: $ibmplexRegular, $color: #747373);
            padding-left: 5px;
          }
        }
        .MuiFormControl-marginNormal{
          margin-bottom: 0;
        }
        .mr-initiate-form{
          padding: 10px 15px 15px 15px;
        }
      }
    }
    
    .mr-cashflow-listing{
      .MuiTabs-scroller{margin: 0 !important;}
      h1{
        margin-bottom: 16px;
        a{
          @include elementStyles($size: 18px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
          padding-right: 10px;
        }
      }
      .cashflow-filters{
        li{
          min-width: 240px;
          margin-right: 15px;
        }
      }
      .cashflow-header{
        background: #B5BEDB;
        border: 1px solid #dfe3e6;
        span{
          padding: 6px 10px;
          display: inline-block;
          @include elementStyles($size: 14px, $fontFamily: $ibmplexSemiBold, $color: rgba(0,0,0,.6));
        }
      }
      .cashflow-list{
        &:nth-child(even){
          background: #f6f8ff !important;
        }
        &:nth-child(odd){
          background: #fff;
        }
        &:hover{
          background: #e6ebfb !important;
        }
        .monthly-total{
          p{
            @include elementStyles($size: 13px, $fontFamily: $ibmplexSemiBold, $color: #000000);
          }
        }
        .cashflow-row{
          .first-card{
            background: #ffffff !important;
            position: relative;
            .cashflow-acc{
              position: absolute;
              z-index: 9;
              top: 2px;
              .month-name{
                font-family: $ibmplexSemiBold;
                font-weight: 700;
              }
            }
          }
          .month-block{
            border-top: 1px solid #dfe3e6;
            padding: 15px 15px 5px  15px;
          }
          .border-col{
            border-left: 1px solid #dfe3e6;
          }
          .cash-info{
            padding: 6px 10px;
            border-bottom: 1px solid #dfe3e6;
          }
        }
        &:first-child{
          .month-block{
            border-top: none;
          }
        }
        &:last-child{
          .first-card{
            border-bottom: 1px solid #dfe3e6 !important;
          }
        }
      }
    }
  }
  // Modal Styling
  .comments-wrapper{
    width: 420px;
    .comment-input-disabled{
      height: auto;
      border: none !important;
      textarea{
        border: none !important;
      }
    }
    .comment-input{
      min-height: 80px;
      border: 1px solid #d7d7d7;
      border-radius: 4px;
      margin-bottom: 5px;
      textarea{
        padding: 8px;
      }
    }
    .comments-header{
      padding:10px 15px;
      border-bottom: 1px solid #d7d7d7;
      h2{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexMedium, $weight:500, $color: #000);        
      }
    }
    .comments-body{
      padding: 0 15px;
      .comment-card{
        border-bottom: 1px solid #d7d7d7;
        padding: 10px 0;
        .comment-user-info{
          li{
            margin-right: 5px;
            span{
              @include elementStyles($size: 12px, $fontFamily: $ibmplexRegular, $color: rgba(0, 0, 0, 0.54));
            }
            svg{
              font-size: 15px;
            }
          }
        }
        &:last-child{
          border-bottom: none;
        }
      }
    }
    .comments-footer{
      border-top: 1px solid #d7d7d7;
      padding: 5px 15px;
    }

  }
  .workscope-moreinfo-modal{
    h4{
      @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0, $margin:0 0 12px 0);
    }
  }
  .engine-derate-modal{
    .quickmr-estimted-time{
      padding-right: 10px;
      color: #ff0000;
      font-size: 14px;
    }
    .loi-row{
      margin-bottom: 18px;
      label{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: rgba(0, 0, 0, 0.54));
      }
      span{
        color: rgb(255, 193, 7);
        font-size: 12px;
      }
      table{
        width: 100%;
        border: 1px solid #e1e1e1;
        tr{
          td{
            border-right: 1px solid #e1e1e1;
            text-align: center;
            padding: 10px 0;
            &:last-child{
              border-right: none;
            }
            &.base-ratio{
              background: #b0ccea;
            }
            &.selected{
              background: #4A90E2;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .derate-matrix-blk-modal{
    table{
      width: 100%;
      border: 1px solid #6794c8;
      thead{
        th{
          background: #afcbe9;
          padding: 10px;
          border: 1px solid #6794c8;
          font-family:$ibmplexRegular;
          font-size:14px;
        }
      }
      tbody{
        td{
          padding: 10px;
          border: 1px solid #6794c8;
          font-family:$ibmplexRegular;
          font-size:14px;
          &:first-child{
            background: #afcbe9;
            width: 140px;
          }
        }
      }
    }
  }
  .derate-matrix-blk-modal table {
    width: auto;
    min-width: 100%; 
    white-space: nowrap; 
  }
  
  .derate-matrix-blk-modal th,
  .derate-matrix-blk-modal td {
    padding: 8px; 
  }
  
  .derate-matrix-blk-modal table,
  .derate-matrix-blk-modal th,
  .derate-matrix-blk-modal td {
    border: 1px solid #ccc; 
  }
  
  .llp-mr-split-modal{
    h4{
      color: rgba(0,0,0,.54);
      margin-bottom: 6px;
      font-size: 14px;
    }
    table{
      th,td{padding: 8px 16px}
      th{
        color: #6D9BB9;
      }
    }
  }
  .eol-projection-heading{
    margin-bottom: 25px;
  }
  .log-graph-title{
    padding: 10px 10px;
    font-weight: 600;
    font-size: 16px;
    background-color: #B5BEDB;
    display: flex;
    border-bottom:1px solid #cbcbcb;
    justify-content: space-between;
  }
  @media only screen and (min-width: 601px) and (max-width: 960px){
    .mr-calculator-section{
      .mr-log-info{
        .MuiButton-root{
          float: right;
        }
      }
      .mr-calculator-initiate{
        .metrics{
          margin-bottom: 5px;
        }
        form{
          .map-section{
            input{
              width: 50%!important;
            }
          }
        }
      }
      .mr-cashflow-listing{
        .cashflow-list{
          .cashflow-row{
            .cash-info{
              font-size: 13px;
              padding: 10px;
              display: inline-block;
              border-bottom: none;
              .cashflow-event-hd-mobile{
                color: #6d9bb9;
                font-size: 12px;
                margin-bottom: 2px;
              }
            }
            .month-block{
              padding: 20px 10px 5px 10px;
              color: #BD10E0;
              .monthly-total{
                margin-top: 5px;
                span{
                  color: #6d9bb9;
                }
              }
            }
          }
        }
        .cashflow-header{
          span{
            padding: 8px;
            font-size: 12px;
          }
        }
        .cashflow-filters{
          border-bottom: 1px solid #dfe3e6;
          padding-bottom: 15px;
        }
      }
      .generate-report-button{
        margin-top: auto;
      }
    }
  }
}
